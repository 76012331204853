import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { UserContext, useNavigateWithContext } from 'state';
import { useResetCode } from 'hooks/useSessionVariables';
import { scaleFonts } from 'components/font-select';
import LegacyRoutes from './legacyRoutes';
import ApplicantRoutes from './applicantRoutes';
import ApproverRoutes from './approverRoutes';
import AppLayout from '../layout';
import { LocationRoutes, ListingRoutes, LoginRoutes } from './commonRoutes';
import ErrorPage from '../errors';
import Login from '../login';
import MobileRedirect from '../mobile-redirect';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  @media print {
    width: initial;
    height: initial;
    overflow: initial;
    .doNotPrint,
    .doNotPrint * {
      visibility: hidden;
    }
  }
`;

const OrgRoutes = ({ updateChatClient }) => {
  return (
    <AppLayout>
      <Routes>
        <Route
          path="applicant/*"
          element={<ApplicantRoutes updateChatClient={updateChatClient} />}
        />
        <Route path="showing/*" element={<ApplicantRoutes updateChatClient={updateChatClient} />} />
        <Route path="approver/*" element={<ApproverRoutes updateChatClient={updateChatClient} />} />
        {LoginRoutes}
        {LocationRoutes}
        {ListingRoutes}
        <Route path="*" element={<LegacyRoutes updateChatClient={updateChatClient} />} />
        <Route index={true} element={<Login />} />
      </Routes>
    </AppLayout>
  );
};

export default ({ updateChatClient }) => {
  const navigate = useNavigateWithContext();
  const { userContext } = React.useContext(UserContext);
  const resetCode = useResetCode();

  React.useEffect(() => {
    if (!userContext?._id && resetCode) {
      navigate(`home/signup/reset`);
    }
  }, [resetCode]);

  React.useEffect(() => {
    const textScale = Number(localStorage.getItem('textScale'));
    if (!textScale || !Number.isFinite(textScale)) return;
    scaleFonts(textScale);
  }, []);

  return (
    <Wrapper id="application-root-wrapper">
      <Routes>
        <Route path=":orgId/*" element={<OrgRoutes updateChatClient={updateChatClient} />} />
        <Route path=":orgId/applicant/mobile-redirect" element={<MobileRedirect />} />
        <Route index={true} element={<ErrorPage errorValue={'No matching route!'} />} />
      </Routes>
    </Wrapper>
  );
};
