import React, { memo } from 'react';
import Input from './input';
import Text from './text';
import { SingleContact, MultiContact } from './contact';
import Boolean from './form-field-boolean';
import InputDate, { InputDateTime } from './input-date';
import CurrencyInput from './currency-input';
import AdvancedSelect from './advanced-select';
import InputFile from './input-file';
import RichText from './rich-text-editor';
import Signature from './signature-pad';
import CardsTypeAhead from './cards-typeahead';
import { AddressInput } from 'components/address';
import InputPhone from 'components/input-phone';
import styled from 'styled-components';

const FormControls = {
  string: Input,
  'rich-text': RichText,
  email: Input,
  url: Input,
  date: InputDate,
  'datetime-local': InputDateTime,
  number: Input,
  tel: Input,
  money: CurrencyInput,
  sourceselect: AdvancedSelect,
  singleselect: AdvancedSelect,
  multiselect: AdvancedSelect,
  stage: AdvancedSelect,
  boolean: Boolean,
  address: AddressInput,
  files: InputFile,
  file: InputFile,
  signature: Signature,
  relatedAptlets: CardsTypeAhead,
  text: Text,
  person: SingleContact,
  persons: MultiContact
};

const EmptyControlPlaceHolder = styled.div`
  height: 36px;
  border-radius: 6px;
  background-color: #eee;
  padding: 0 5px;
  display: flex;
  align-items: center;
`;

export default memo(({ value, onChange, field, ...props }) => {
  if (!field) return <EmptyControlPlaceHolder>Field does not exist</EmptyControlPlaceHolder>;
  const FormField = FormControls[field.type];
  if (!FormField) {
    return (
      <EmptyControlPlaceHolder>{`Form field type: ${field.type} does not exist`}</EmptyControlPlaceHolder>
    );
  }
  return <FormField field={field} value={value} onChange={onChange} {...props} />;
});
