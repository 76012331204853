import toast from 'react-hot-toast';
import { LOGIN_SSO } from '../user';

export const msftLogin = ({ response, ssoLogin, ssoRegister }) => {
  LOGIN_SSO({
    sso: {
      type: 'microsoft',
      idToken: response.idToken,
      accessToken: response.accessToken
    }
  })
    .then(async (loginResponse) => {
      if (loginResponse.status) {
        ssoLogin(loginResponse);
        return;
      }
      let profile = response.account;
      ssoRegister({
        email: profile.username,
        firstName: profile.name,
        sso: {
          type: 'microsoft',
          idToken: response.idToken,
          accessToken: response.accessToken
        }
      });
    })
    .catch((err) => {
      console.error({ errData: err.data, err });
      toast.error(err.data?.message ? err.data?.message : err.message || err.name);
    });
};
