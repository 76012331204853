import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const MobileRedirect = () => {
  const [audience, setAudience] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const audience = queryParams.get('audience');
    const code = queryParams.get('code');
    const context = queryParams.get('context');
    setAudience(audience);

    const appUrls = {
      tourManager: `com.getaptly.aptlytourmanager://shareCode/${code}`,
      tourResidence: `com.aptlymobile.aptlytourresident://shareCode/${code}`,
      resziTourResidence: `com.aptlymobile.resziresident://shareCode/${code}`
    };

    const appUrl = appUrls[context || audience] || ''; // Default to empty if audience not found

    // Try to open the app
    if (appUrl) {
      window.location.href = appUrl;
    }
  }, []);

  return (
    <Container>
      <Title>You're Invited!</Title>
      <ContentSection>
        <Description>To access your invitation, please follow these steps:</Description>
        <StepsList>
          <li>Download the app from your device's app store.</li>
          <li>Return to this page after the installation is complete.</li>
          <li>A modal will automatically appear, asking if you want to open the app.</li>
          <li>Log in or create an account to accept your invitation.</li>
        </StepsList>
      </ContentSection>
      <Footer>After installing the app, return to this page to accept your invitation.</Footer>
    </Container>
  );
};

export default MobileRedirect;

const Container = styled.div`
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 24px;
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;
`;

const StepsList = styled.ol`
  text-align: left;
  margin-top: 20px;
`;

const Footer = styled.p`
  color: #666;
  font-size: 14px;
`;

const ContentSection = styled.div`
  margin-bottom: 32px;
`;
