import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import { Row, Title, PageContainer, PageWrapper } from '../common-styles';

export default ({ application, saveApplicationPart, onBack, onRefresh }) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('income_source.title')}</Title>
        </Row>
        {!!application.noRentReason?.length && (
          <Row>
            {t('income_source.not_contributing_description')}
            <br />
            {t('income_source.not_contributing_description2')}
            <ul>
              {application.noRentReason.map((reason, ix) => (
                <li key={ix}>{t(`skip_income_modal.reason_${reason}`)}</li>
              ))}
            </ul>
          </Row>
        )}
        {!!application.noRentComments && (
          <Row>
            Additional Comments:
            <div>{application.noRentComments}</div>
          </Row>
        )}
        <Row className="center">
          <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
            <Button radius="25px" width={107} variant="secondary" onClick={onBack}>
              {t('buttonsActions.backButton')}
            </Button>
            <Button width={107} radius="25px" variant="primary" onClick={onBack}>
              {t('buttonsActions.next')}
            </Button>
          </div>
          <Button
            variant="link"
            height={40}
            onClick={() => {
              saveApplicationPart({ noRentContribution: false }).then(() => onRefresh());
            }}>
            {t('buttonsActions.changeIncomeSkip')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
