import { Trans } from 'react-i18next';

export const LAYOUTS = {
  application: (params) => ({
    type: 'application',
    routes: {
      overview: `app/applications/form/v2/${params.applicationId}`,
      home: 'app/applications'
    },
    isOverview: (p) => p?.['*'] === 'summary',
    isCreate: (p) => !p?.applicationId && !p?._id,
    text: {
      goOverview: <Trans i18nKey="buttonsActions.overview" />,
      goHome: <Trans i18nKey="buttonsActions.allApplications" />
    }
  }),
  showing: (params) => ({
    type: 'showing',
    routes: {
      overview: `app/showing/${params.objectId}/summary`,
      home: 'app/showing'
    },
    isOverview: () => true,
    isCreate: (p) => !p?.objectId && !p?._id,
    text: {
      title: <Trans i18nKey="layouts.showing.title">Request a tour</Trans>,
      goOverview: <Trans i18nKey="layouts.showing.goOverview">My tours</Trans>,
      goHome: <Trans i18nKey="layouts.showing.goHome">All Tours</Trans>
    }
  })
};
