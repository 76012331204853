import React from 'react';
import styled from 'styled-components';
import html2React from 'html-react-parser';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: ${({ bgColor }) => bgColor};
`;

export default ({ html }) => {
  const [bgColor, setBgColor] = React.useState();
  React.useEffect(() => {
    const bg = document.getElementsByTagName('table')?.[0]?.style?.backgroundColor;
    bg && setBgColor(bg);
  }, []);

  const elements = html2React(html, {
    transform(reactNode, domNode, index) {
      if (
        domNode?.name === 'a' &&
        (domNode?.attribs?.['href'] || '').startsWith('https://calendly.com/')
      ) {
        return React.cloneElement(reactNode, {
          onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            Calendly.initPopupWidget({ url: domNode?.attribs?.['href'] });
          }
        });
      }
      return reactNode;
    }
  });

  return (
    <Wrapper bgColor={bgColor}>
      <div>{elements}</div>
    </Wrapper>
  );
};
