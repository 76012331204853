import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from '../state';
import Page from '../page';
import Toolbar from './toolbar';
import Controls from './controls';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #eee;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
`;

const Scrollable = styled.div`
  display: grid;
  flex: 1;
  overflow-y: auto;
  flex-shrink: 0;
`;

const Zoom = styled.div`
  display: grid;
  justify-items: center;
  gap: 20px;
  scale: ${({ zoom }) => zoom}%;
  transform-origin: top center;
  padding: 20px 0;
`;

const getPageSize = ({ page }) => {
  const { scale = 1 } = page;
  const renderScale = 1.33;
  const width = Math.floor(page.width / scale);
  const height = Math.floor(page.height / scale);
  return {
    pageWidth: scale === 1 ? page.width : Math.floor(width * renderScale),
    pageHeight: scale === 1 ? page.height : Math.floor(height * renderScale)
  };
};

export default () => {
  const { doc, user, ui, pages } = useDocumentContext();
  const [zoom, setZoom] = React.useState(100);
  const containerRef = React.useRef(null);
  const zoomRef = React.useRef();

  const { currentPage } = ui;

  React.useEffect(() => {
    setTimeout(() => {
      const containerWidth = containerRef.current.offsetWidth;
      const { pageWidth } = getPageSize({ page: doc.pages[0] });
      if (containerWidth < pageWidth) {
        const relativeZoom = Math.round((containerWidth * 100) / pageWidth) - 2;
        setZoom(relativeZoom);
        zoomRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'center'
        });
      } else {
        containerRef.current.scrollTop = 0;
      }
    }, 100);
  }, []);

  const handleZoom = (dir) => {
    const newZoom = dir === 'out' ? zoom - 10 : zoom + 10;
    setZoom(newZoom < 0 ? 10 : newZoom);
  };

  React.useEffect(() => {
    if (currentPage?.uuid) {
      document.getElementById(`page_${currentPage.uuid}`).scrollIntoView();
    }
  }, [currentPage?.uuid]);

  return (
    <Wrapper>
      {!user.hasSigned && !ui.allSigned && user.requireSignature && !doc.isExpired && (
        <Toolbar
          blocks={ui.fillableBlocks}
          setFocusedBlock={ui.setFocusedBlock}
          onAssistant={() => ui.updateUi({ completeAssistant: true })}
        />
      )}
      <Scrollable ref={containerRef}>
        <Zoom zoom={zoom} ref={zoomRef}>
          {ui.pages.map((page) => (
            <Page page={page} key={page.uuid} zoom={zoom} />
          ))}
        </Zoom>
      </Scrollable>
      <Controls handleZoom={handleZoom} />
    </Wrapper>
  );
};
