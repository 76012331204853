import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AUDIENCES } from 'helpers/enums';
import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import Button from 'components/button';
import Text from 'components/text';
import { FieldGroup, Title, SubTitle, BoxNote, ActionsRow, Loader } from './common';
import DatePicker from 'react-datepicker';

const Wrapper = styled.div`
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}

  .input-time {
    width: -webkit-fill-available;
    padding: 0 10px;
    cursor: pointer;
  }
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--selected {
    background-color: var(--color-link) !important;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: solid 1px #d4dae1;
  }
  .react-datepicker__time-container {
    border-left: solid 1px #d4dae1;
  }
  .react-datepicker {
    border: solid 1px #d4dae1;
  }
`;

const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;

export default ({ values: initialValues, action, isMobile, onSubmit, location }) => {
  const { userContext } = React.useContext(UserContext);
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    tourDate: yup.date().required(`${t('form.validate.provideTourDate')}`),
    tourComments: yup.string().max(5000, `${t('form.validate.long')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      tourDate: action?.tourDate || initialValues?.tourDate || '',
      tourComments: action?.tourComments || initialValues?.tourComments || ''
    },
    validationSchema: applicantInfoSchema,
    onSubmit: (vals) => {
      onSubmit({ tourDate: vals.tourDate, tourComments: vals.tourComments });
    }
  });

  const [tourNotes, tourNotesInProgress, tourNotesFetched, tourFailedFetch] = useAPIData(
    'application.TOUR_NOTES',
    !location?.showingConfig?.tourInformationTemplateId
      ? undefined
      : {
          userContext,
          applicationId: action?._id,
          contextId: location?._id,
          bypass: true,
          audience: AUDIENCES.showing.uuid
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [action?._id, action?.locationId]
    }
  );

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    // Set the time of currentDate to the start of the day
    currentDate.setHours(0, 0, 0, 0);

    // Set the time of selectedDate to the start of the day
    selectedDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() <= selectedDate.getTime(); // Include the current date
  };

  const loadingNotes = location?.showingConfig?.tourInformationTemplateId
    ? !tourNotesFetched && !tourFailedFetch
    : false;

  if (loadingNotes) {
    return (
      <Wrapper isMobile={isMobile}>
        <Title>
          <Trans i18nKey="showing.detail.tour.title">Tour Information</Trans>
        </Title>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper isMobile={isMobile}>
      <Title>
        <Trans i18nKey="showing.detail.tour.title">Tour Information</Trans>
      </Title>
      <SubTitle>
        <Trans i18nKey="showing.detail.tour.subTitle">When would you like to tour our home?</Trans>
      </SubTitle>
      <FieldGroup
        label={
          <Trans i18nKey="showing.detail.tour.tourDate">
            When would you like to tour our home?
          </Trans>
        }
        errorText={touched.tourDate && errors.tourDate}>
        <DatePicker
          selected={values.tourDate ? new Date(values.tourDate) : new Date()}
          className="input-time"
          onChange={(date) =>
            handleChange({
              target: {
                value: date,
                name: 'tourDate'
              }
            })
          }
          filterTime={filterPassedTime}
          filterDate={filterPassedDate}
          showTimeSelect
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </FieldGroup>
      <FieldGroup
        label={<Trans i18nKey="showing.detail.tour.comments">Comments</Trans>}
        errorText={touched.tourComments && errors.tourComments}>
        <Text
          name="tourComments"
          rows="3"
          value={values.tourComments}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FieldGroup>
      <BoxNote>
        {tourNotes?.content ? (
          <div dangerouslySetInnerHTML={{ __html: tourNotes?.content }} />
        ) : (
          <>
            <strong>
              <Trans i18nKey="showing.detail.tour.noteTitle">Please Note</Trans>
            </strong>
            <p>
              <Trans i18nKey="showing.detail.tour.noteTitle">
                Your requested date/time needs to be reviewed by our leasing team. Once approved,
                you will be contacted with timing and instructions for accessing the home.
              </Trans>
            </p>
          </>
        )}
      </BoxNote>
      <ActionsRow>
        <StyledButton variant="primary" onClick={handleSubmit}>
          Next
        </StyledButton>
      </ActionsRow>
    </Wrapper>
  );
};
