import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import Button from 'components/button';
import { useGoogle } from 'dal/sso/google';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest, msalConfig } from '../../../helpers/msftAuthConfig';
import { msftLogin } from '../../../dal/sso/microsoft';
import { PublicClientApplication } from '@azure/msal-browser';

export const LoginButton = styled(Button)`
  cursor: pointer;
  display: flex;
  height: 40px !important;
  border-radius: 25px !important;
  gap: 10px;
  align-items: center;
  min-width: 200px;
  padding: 15px;
  text-transform: uppercase;
  justify-content: center;
  &.primary {
    background-color: #00a1ff;
    font-weight: 900;
    color: #fff;
  }
  &.secondary {
    border: solid 1px #00a1ff;
    color: #00a1ff;
    background-color: #fff;
  }
  &.msft {
    border: solid 1px #dadce0;
    display: flex;
    flex-direction: row;
    text-transform: none;
    background-color: #fff;
    background-image: none;
    color: #3c4043;
    cursor: pointer;
    font-family: 'Google Sans', arial, sans-serif;
    font-size: 14px;
    height: 38px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 400px;
    min-width: 200px;
    :hover {
      border-color: #d2e3fc;
      background: rgba(66, 133, 244, 0.04);
      transition: background-color 0.218s;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  gap: 20px;
  .button-row {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    > div,
    > button {
      flex: 1;
      width: 100%;
      min-height: 40px;
      height: 40px;
    }

    &.login {
      @media (min-width: 431px) {
        flex-wrap: nowrap;
        flex-flow: row;
        > button {
          flex: 1;
        }
        > div {
          flex: 1;
        }
      }
    }
  }
  .forgot-password {
    align-self: flex-end;
  }
  #googleSignInParentElement,
  #googleSignIn {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
  }
`;
const msalInstance = new PublicClientApplication(msalConfig);

const MsftSSOButton = React.memo(({ handleActions }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((r) => {
        msftLogin({
          response: r,
          ssoLogin: (response) => {
            handleActions({ id: 'ssoLogin', data: response });
          },
          ssoRegister: (response) => {
            handleActions({ id: 'ssoRegister', data: response });
          }
        });
      })
      .catch((e) => {
        console.log('LOGIN ERROR', e);
      });
  };

  return (
    <LoginButton className={'msft'} onClick={() => handleLogin()}>
      <img
        src={'https://d19uz2b1vsd3mq.cloudfront.net/p/assets/images/icons/icon_office365.svg'}
        alt="msft"
      />
      <span> Login with MSFT 365</span>
    </LoginButton>
  );
});

const GoogleSSOButton = React.memo(({ handleActions }) => {
  const [buttonSetup, setButtonSetup] = React.useState(false);
  const [isReady, setupButton] = useGoogle({
    onLogin: (response) => {
      handleActions({ id: 'ssoLogin', data: response });
    },
    onRegister: (response) => {
      handleActions({ id: 'ssoRegister', data: response });
    }
  });

  const createButton = () => {
    if (buttonSetup) return;

    setButtonSetup(
      setupButton({
        buttonId: 'googleSignIn',
        signUp: false,
        parentElementId: 'googleSignInParentElement'
      })
    );
  };

  React.useEffect(() => createButton, [isReady]);

  return (
    <div id="googleSignInParentElement">
      <div id="googleSignIn" />
    </div>
  );
});

export const LoginActions = ({
  state,
  handleActions,
  onCreateAccount,
  onLogin,
  onForgotPassword
}) => {
  const CreateAccount = () => (
    <div className="button-row">
      <LoginButton
        className={'primary'}
        onClick={onCreateAccount || (() => handleActions({ id: 'setView', data: 'signup' }))}>
        Create Account
      </LoginButton>
    </div>
  );

  return (
    <MsalProvider instance={msalInstance}>
      <ButtonWrapper>
        {state === 'login' ? (
          <Button
            className="forgot-password"
            variant="link"
            onClick={onForgotPassword || (() => handleActions({ id: 'forgotPassword' }))}>
            <Trans i18nKey="homePage.forgotPassword">Forgot your password?</Trans>
          </Button>
        ) : (
          <CreateAccount />
        )}
        <LoginButton
          className={'secondary'}
          onClick={onLogin || (() => handleActions({ id: 'setView', data: 'login' }))}>
          Login
        </LoginButton>

        <div className="button-row login">
          <MsftSSOButton handleActions={handleActions} />
          <GoogleSSOButton handleActions={handleActions} />
        </div>

        {state !== 'login' ? <></> : <CreateAccount />}
      </ButtonWrapper>
    </MsalProvider>
  );
};
