import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import { randomId } from 'dal/utils';
import Button from 'components/button';
import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import IncomeModal from './income-edition';
import SkipIncome from '../income/skip-income';
import IncomeList from './income-list';
import NotContributing from './not-contributing';

const INCOME_OPTIONS = (t) => {
  return [
    {
      value: 'employment',
      label: `${t('income_source.options.fullTime')}`
    },
    {
      value: 'self',
      label: `${t('income_source.options.contrating')}`
    },
    {
      value: 'misc',
      label: `${t('income_source.options.other')}`
    }
  ];
};

const OptionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  color: var(--color-primary);
  > div {
    width: 100px;
  }
  .Option_label {
    font-size: 0.75em;
    text-transform: capitalize;
  }
`;

export default ({
  application,
  location,
  saveApplicationPart,
  onBack,
  onNext,
  onRefresh,
  backToOverview
}) => {
  const [incomeEdit, setIncomeEdit] = React.useState();
  const [incomes, setIncomes] = React.useState(application.income || []);
  const { t } = useTranslation();

  const removeIncome = ({ uuid }) => {
    setIncomes(incomes.filter((inc) => inc.uuid !== uuid));
  };

  const handleNext = () => {
    if (incomes.length === 0) {
      toast('Please provide at least one form of income');
    }
    saveApplicationPart({ income: incomes.map((z) => Object.assign(z, { isManual: true })) }).then(
      () => onNext()
    );
  };

  React.useEffect(() => {
    if (!application.manualVerification) {
      saveApplicationPart({ manualVerification: true }, false);
    }
  }, []);

  const handleAdd = (income) => {
    const isEdit = incomes.some((p) => p.uuid === income.uuid);
    setIncomes(
      isEdit
        ? incomes.map((p) => {
            if (p.uuid !== income.uuid) return p;
            return income;
          })
        : [...incomes, income]
    );
  };
  if (application?.noRentContribution) {
    return (
      <NotContributing
        application={application}
        saveApplicationPart={saveApplicationPart}
        onBack={onBack}
        onRefresh={onRefresh}
        backToOverview={backToOverview}
      />
    );
  }
  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('income_source.title')}</Title>
        </Row>
        <Row>
          <Description className="text-center">{t('income_source.description')}</Description>
        </Row>
        <Row>
          {INCOME_OPTIONS(t).map((option) => (
            <IncomeList
              key={option.value}
              title={option.label}
              onAdd={() => setIncomeEdit({ type: option.value, uuid: randomId(), current: true })}
              items={incomes.filter((item) => item.type === option.value)}
              onRemove={removeIncome}
              onEdit={setIncomeEdit}
            />
          ))}
        </Row>
        <Row className="center">
          <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
            <Button radius="25px" width={107} variant="secondary" onClick={onBack}>
              {t('buttonsActions.backButton')}
            </Button>
            <Button
              disabled={!incomes.length}
              width={107}
              radius="25px"
              variant="primary"
              onClick={handleNext}>
              {t('buttonsActions.next')}
            </Button>
          </div>
          <SkipIncome
            saveApplicationPart={saveApplicationPart}
            application={application}
            onNext={onNext}
            backToOverview={backToOverview}
          />
        </Row>
      </PageContainer>
      <IncomeModal
        location={location}
        open={!!incomeEdit}
        income={incomeEdit}
        onClose={() => setIncomeEdit(undefined)}
        onSave={handleAdd}
      />
    </PageWrapper>
  );
};
