import React from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import startCase from 'lodash/startCase';

import { UserContext } from 'state';
import Button from 'components/button';
import Icon from 'components/icon';
import { SupportModal } from 'components/contactUs';
import { APPLICATION_REPORT } from 'dal/applications';

const ApprovalWrapper = styled.div`
  position: relative;
  min-height: 30px;
  margin-left: 25px;
  line-height: 35px;

  > img {
    width: 50px;
    position: absolute;
    top: -10px;
    left: -40px;
  }
`;
const ConditionalWrapper = styled.div`
  margin-bottom: 5px;
  font-style: italic;
`;

const StatusMessage = styled.div`
  border: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  line-height: 1.4;
  &.submitted {
    background-color: #fffed1;
    border-color: #f7b500;
  }
  &.denied {
    border-color: #e02020;
    background-color: rgba(224, 32, 32, 0.1);
  }
  &.approved {
    border-color: #6dd400;
    background-color: #e6ffcd;
  }
  .submit-col,
  .action-col {
    min-width: 175px;
    max-width: 175px;
    width: 175px;
    button {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
  .status-col {
    flex-grow: 1;
  }
`;

const createSectionKey = (id) => `section_${id}`;

export default ({ application, location }) => {
  const { userContext } = React.useContext(UserContext);
  const [showContactModal, setShowModal] = React.useState();
  const [loading, setLoading] = React.useState(false);
  if (!application?.paid || !application?.submitted || !application?.verified) {
    return <></>;
  }

  const downloadReport = () => {
    if (loading) return;
    setLoading(true);
    APPLICATION_REPORT({ userContext, reportType: 'credit', applicationId: application._id })
      .then((r) => {
        const applicantName = [application?.applicant?.firstName, application?.applicant?.lastName]
          .filter((z) => z)
          .join(' ');
        saveAs(
          new Blob([new Uint8Array(r.bytes)], { type: 'application/pdf' }),
          `${startCase((applicantName || '')?.toLowerCase()).replace(
            /[^\w\d\s]/g,
            ''
          )} - Consumer Credit Report.pdf`
        );
      })
      .catch((err) => {
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const status = application.status?.isApproved
    ? 'approved'
    : application.status?.isApproved === false
    ? 'denied'
    : 'submitted';

  const pendingRelatedApps = !!application.relatedAppData?.applications?.filter(
    (app) => !app.completed
  );

  return (
    <StatusMessage id={createSectionKey('statusMessage')} className={status.toLowerCase()}>
      <div className="column submit-col">
        <div className="title">
          <strong>Submitted</strong>
        </div>
        <div>
          {application.submittedAt
            ? new Date(application.submittedAt)?.renderLocale()
            : new Date(application.updatedAt || application.createdAt)?.renderLocale()}
        </div>
      </div>
      <div className="column status-col">
        <div className="title">
          <strong>Status</strong>
          {status === 'submitted' && <div>Your application is being processed.</div>}
          {status === 'approved' && (
            <ApprovalWrapper>
              <img src="/assets/confetti.gif" alt="" />
              <strong>APPROVED!</strong>
            </ApprovalWrapper>
          )}
          {status === 'approved' && application?.status?.isConditional && (
            <ConditionalWrapper>
              This approval is conditional and requires meeting additional criteria. Check your
              email for details on this decision.
            </ConditionalWrapper>
          )}
          {status === 'denied' && (
            <div>
              Denied
              <br />
              Check your emails for details on this decision
            </div>
          )}
          {pendingRelatedApps?.length > 0 && (
            <div>
              Waiting for co-applicants ({pendingRelatedApps.map((app) => app.name).join(', ')})
            </div>
          )}
          <div className="column center">
            <Button variant="link" onClick={() => setShowModal(true)}>
              Request an update from {location?.companyInfo?.companyName || location?.name}
            </Button>
          </div>
        </div>
      </div>
      {!!application?.creditComplete && (
        <div className="column action-col">
          <Button variant="primary" onClick={downloadReport} disabled={!!loading}>
            {loading ? <Icon name="fa-duotone fa-spinner-third fa-spin" /> : <></>}
            <span>View Credit Report</span>
          </Button>
        </div>
      )}
      {showContactModal && (
        <SupportModal
          width="600px"
          height="620px"
          modalOpen={showContactModal}
          setModalOpen={() => setShowModal(false)}
          isContactUs={true}
        />
      )}
    </StatusMessage>
  );
};
