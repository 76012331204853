import React from 'react';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalScrollable } from 'components/modal';
import { useGenerateCode } from '../hooks/useGenerateCode';
import Icon from '../../../components/icon';
import { format, parseISO, setMinutes } from 'date-fns';

const formatDate = (date) => {
  const parsedDate = parseISO(date || new Date().toISOString());
  const dateWithZeroMinutes = setMinutes(parsedDate, 0);
  return format(dateWithZeroMinutes, 'MM/d/yy h:mm a');
};
export default ({ onClose, showing }) => {
  const { generateCode, accessCode, isLoading } = useGenerateCode({ showing });

  React.useEffect(() => {
    generateCode();
  }, []);

  return (
    <Modal open height="auto" width="30%" onClose={onClose}>
      <HeaderValue>Unit access code</HeaderValue>
      <ModalScrollable>
        {isLoading ? (
          <ContentHolder>
            <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={100} />
          </ContentHolder>
        ) : (
          <BodyWrapper>
            {accessCode ? (
              <>
                <div>
                  <Label>Passcode: </Label>
                  {accessCode?.passcode}
                </div>
                <div>
                  <Label>Valid period: </Label>
                  {accessCode?.startDate && formatDate(accessCode?.startDate)} -{' '}
                  {accessCode?.endDate && formatDate(accessCode?.endDate)}
                </div>
              </>
            ) : (
              <Label>Make sure you are in front of the Unit.</Label>
            )}
          </BodyWrapper>
        )}
      </ModalScrollable>
    </Modal>
  );
};

const ContentHolder = styled.div`
  height: 110px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const Label = styled.span`
  font-weight: bold;
`;

const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  display: flex;
  font-size: 30px;
  flex-shrink: 0;
`;

const HeaderValue = styled(ModalHeader)`
  font-size: 1.25em;
`;
const BodyWrapper = styled.div`
  max-width: 600px;
`;
const Details = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  align-items: center;

  > div {
    display: flex;
    flex-flow: column;
    word-break: break-word;
    font-size: 1.13em;
    gap: 10px;
  }
`;
const ActionRowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  word-break: break-word;
  font-size: 1.13em;
  border-top: 1px solid #e6e6e6;

  > div {
    padding: 10px 5px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: #e6e6e6;
    }
  }
`;
