import React from 'react';
import { UserContext } from '../../../state';
import toast from 'react-hot-toast';
import { CREATE_DEVICE_CODE, GET_DEVICE_CODE } from '../../../dal/ttLock';
import { format, parse } from 'date-fns';

function getDistanceAndCheckProximity(lat1, lng1, lat2, lng2, threshold = 20) {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const earthRadiusMeters = 6371000; // Earth's radius in meters

  const dLat = toRadians(lat2 - lat1);
  const dLng = toRadians(lng2 - lng1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadiusMeters * c;

  return {
    isWithinThreshold: distance <= threshold,
    distance: distance
  };
}

export const useGenerateCode = ({ showing }) => {
  const { userContext } = React.useContext(UserContext);
  const [accessCode, setAccessCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const generateCode = () => {
    setIsLoading(true);
    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by this browser.');
      return setIsLoading(false);
    }

    navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
  };

  const handleGeolocationSuccess = (position) => {
    if (!isValidPosition(position)) {
      toast.error('Geolocation not valid.');
      return setIsLoading(false);
    }

    const comparedCoords = getDistanceAndCheckProximity(
      position.coords.latitude,
      position.coords.longitude,
      showing.location.address.geopoint[1],
      showing.location.address.geopoint[0]
    );

    if (comparedCoords?.isWithinThreshold) {
      fetchDeviceCode()
        .then((resp) => {
          if (!resp) {
            createDeviceCode();
          } else {
            setAccessCode(resp);
            setIsLoading(false);
          }
        })
        .catch(() => {
          toast.error('Failed to get the code.');
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const handleGeolocationError = (error) => {
    console.error('Error getting location:', error);
    toast.error('Location services not enabled.');
    setIsLoading(false);
  };

  const isValidPosition = (position) => {
    return (
      position.coords.latitude &&
      showing?.location?.address?.geopoint?.length > 0 &&
      showing?.location?.ttLockDevice?.lockId
    );
  };

  const fetchDeviceCode = () => {
    return GET_DEVICE_CODE({
      userContext,
      data: {
        appId: showing?._id,
        portalUserId: userContext?._id,
        companyId: userContext?.companyId
      }
    });
  };

  const createDeviceCode = () => {
    const formatString = 'MM/dd/yyyy hh:00 a';
    const currentDate = new Date();
    const formattedDateString = format(currentDate, formatString);

    // Parse the formatted date string back into a Date object
    const parsedDate = parse(formattedDateString, formatString, new Date());

    // Convert the Date object to Unix timestamp (in seconds)
    const unixTimestamp = parsedDate.getTime();

    CREATE_DEVICE_CODE({
      userContext,
      data: {
        appId: showing?._id,
        portalUserId: userContext?._id,
        companyId: userContext?.companyId,
        lockId: Number(showing?.location?.ttLockDevice?.lockId),
        userId: showing?.location?.ttLockDevice?.ownerId,
        startDate: unixTimestamp
      }
    })
      .then((resp) => {
        if (resp.startDate) {
          resp.startDate = new Date(resp.startDate).toISOString();
          resp.endDate = new Date(resp.endDate).toISOString();
        }
        setAccessCode(resp);
        setIsLoading(false);
      })
      .catch(() => {
        toast.error('Failed to create the code.');
        setIsLoading(false);
      });
  };

  return { generateCode, accessCode, isLoading };
};
