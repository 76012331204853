import React from 'react';

const redirectPage = (url) => (window.location.href = url);

export const handleRedirect = (options) => {
  if (!options || !options?.active || !options.url) return;

  if (!options?.duration) {
    redirectPage(options?.url);
  } else {
    setTimeout(() => {
      redirectPage(options?.url);
    }, (options?.duration || 0) * 1000);
  }
};

export default (options) => {
  React.useEffect(() => handleRedirect(options), []);
};
