import { POST, GET, DELETE } from './utils';

export const LIST_APPLICATIONS = ({ userContext, locationId }) =>
  GET({ url: `applications/${locationId || ''}`, token: userContext?.accessToken?.token });

// creates or loads application
export const LOAD_APPLICATION = ({ userContext, locationId, applicationId }) =>
  GET({
    url: `application/${locationId}/${applicationId || ''}`,
    token: userContext?.accessToken?.token
  });

// delete application
export const DELETE_APPLICATION = ({ userContext, locationId, applicationId, data }) =>
  POST({
    url: `application/${locationId}/${applicationId}/delete`,
    data,
    token: userContext.accessToken.token
  });

// update application
export const UPDATE_APPLICATION = ({ userContext, locationId, applicationId, data, options }) => {
  const searchParams =
    options && Object.keys(options).length > 0 ? new URLSearchParams(options) : undefined;
  return POST({
    url: `application/${locationId}/${applicationId}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`,
    data,
    token: userContext?.accessToken?.token
  });
};
// append closed application
export const APPEND_APPLICATION = ({ userContext, locationId, applicationId, data }) =>
  POST({
    url: `application/${locationId}/${applicationId}/append`,
    data,
    token: userContext?.accessToken?.token
  });

// update application
export const CREATE_APPLICATION = ({ userContext, locationId, applicationId, data }) =>
  POST({
    url: `application/${locationId}/${applicationId}/create`,
    data,
    token: userContext?.accessToken?.token
  });

// submit application
export const SUBMIT_APPLICATION = ({ userContext, locationId, applicationId, data }) =>
  POST({
    url: `application/${locationId}/${applicationId}/submit`,
    data,
    token: userContext?.accessToken?.token
  });

// change app Location
export const CHANGE_APP_LOCATION = ({ userContext, locationId, applicationId, newLocationId }) =>
  POST({
    url: `application/${locationId}/${applicationId}/changeLocation`,
    data: { locationId: newLocationId },
    token: userContext?.accessToken?.token
  });

// submit application
export const MANUAL_VERIFICATION = ({ userContext, locationId, applicationId, data }) =>
  POST({
    url: `application/${locationId}/${applicationId}/manualVerification`,
    data,
    token: userContext?.accessToken?.token
  });

// redeem code
export const REDEEM_CODE = ({ userContext, code, data }) => {
  return POST({
    url: `code/redeem`,
    data: { code, data },
    token: userContext?.accessToken?.token
  });
};

// get code summary
export const GET_CODE = ({ code }) => GET({ url: `code/brief/${code}` });

// get code list
export const LIST_SHARES = ({ companyId, userContext }) => {
  return GET({ url: `shares/${companyId}/list`, token: userContext?.accessToken?.token });
};

export const UPDATE_SHARE_DETAILS = ({ userContext, shareId, data }) => {
  return POST({
    url: `share/${shareId}/update`,
    data: data,
    token: userContext?.accessToken?.token
  });
};

// get code details
export const GET_SHARE_DETAILS = ({ userContext, shareId }) => {
  return GET({ url: `share/${shareId}/details`, token: userContext?.accessToken?.token });
};

// upload files
export const ADD_FILE = ({ userContext, locationId, applicationId, field, file, referenceId }) => {
  const searchParams = referenceId ? new URLSearchParams({ refId: referenceId }) : undefined;
  var formData = new FormData();
  formData.append('file', file);
  return POST({
    url: `application/${locationId}/${applicationId}/file/${field}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`,
    body: formData,
    contentType: false,
    token: userContext?.accessToken?.token
  });
};
export const GET_FILE = ({ userContext, locationId, applicationId, field, fileUuid }) => {
  const searchParams = fileUuid ? new URLSearchParams({ fileUuid: fileUuid }) : undefined;
  return GET({
    url: `application/${locationId}/${applicationId}/file/${field}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`,
    contentType: false,
    token: userContext?.accessToken?.token
  });
};
export const REMOVE_FILE = ({ userContext, locationId, applicationId, field, fileUuid }) => {
  const searchParams = fileUuid ? new URLSearchParams({ fileUuid: fileUuid }) : undefined;
  return DELETE({
    url: `application/${locationId}/${applicationId}/file/${field}${
      searchParams ? `?${searchParams.toString()}` : ''
    }`,
    contentType: false,
    token: userContext?.accessToken?.token
  });
};

// append closed application
export const APPLICATION_TERMS = ({ userContext, locationId, applicationId }) =>
  POST({
    url: `application/${locationId}/${applicationId}/terms`,
    token: userContext?.accessToken?.token
  });

export const TOUR_NOTES = ({ userContext, locationId, applicationId }) =>
  POST({
    url: `application/${locationId}/${applicationId}/tourNotes`,
    token: userContext?.accessToken?.token
  });

export const APPLICATION_REPORT = ({ userContext, reportType, applicationId }) =>
  GET({
    url: `applications/${applicationId}/reports/${reportType}`,
    token: userContext?.accessToken?.token
  });
