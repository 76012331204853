import React from 'react';
import styled from 'styled-components';
import Section from './section';
import Icon from 'components/icon';
import { getFullName } from 'utils/stringHelpers';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Trans } from 'react-i18next';

const DataList = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  flex-wrap: wrap;
`;
const DataBox = styled.div`
  font-size: 0.88em;
  .dataBox-label {
    font-weight: bold;
  }
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 10px;
  border-collapse: collapse;
  font-size: 0.88em;
  th {
    text-align: left;
  }
  th,
  td {
    padding: 7px;
  }
  tr {
    border-bottom: 1px solid #ddd;
  }
`;

const Row = styled.div`
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
`;

const ItemWrapper = styled.div`
  &.mobile {
    border: 1px solid #ddd;
    padding: 0 10px;
    margin-top: 10px;
    P {
      line-height: 1.4;
    }
  }
`;
const NoData = styled.div`
  padding: 5px 0;
  font-size: 0.88em;
`;

const CONTACT_ORDER_MAP = {
  primary: 1,
  self: 2,
  emergencyContact: 3,
  other: 4
};

const Adults = ({ t, application, isMobile }) => {
  const otherContacts = []
    .concat(application?.otherContacts)
    .concat(application.relatedAppData?.otherContacts)
    .concat(
      !application?.relatedAppData?.parentApplication
        ? undefined
        : { ...application?.relatedAppData?.parentApplication, type: 'primary' }
    )
    .filter((m) => m)
    .sort((a, b) => {
      const aVal =
        CONTACT_ORDER_MAP[a.applicationId === application?._id ? 'self' : a.type] ||
        CONTACT_ORDER_MAP.other;
      const bVal =
        CONTACT_ORDER_MAP[b.applicationId === application?._id ? 'self' : b.type] ||
        CONTACT_ORDER_MAP.other;
      return aVal - bVal;
    });
  if (!otherContacts.length) return <NoData>None specified</NoData>;

  const includeAddedByCol = !!application.relatedAppData?.otherContacts?.length;

  const labels = {
    minor: t('minor'),
    primary: t('primary'),
    resident: t('resident'),
    partner: t('partner'),
    guarantor: t('guarantor'),
    emergencyContact: t('emergencyContact')
  };

  const getType = (oc) =>
    oc.applicationId === application._id && oc.type !== 'primary' ? 'Self' : labels[oc.type];
  const getStatus = (oc) => {
    if (oc.type === 'emergencyContact') return '---';
    const app = oc.applicationId === application._id ? application : oc.application;

    return !app ? (
      <Trans i18nKey="summary.household.adults.pending">Invite pending</Trans>
    ) : app.stepStatus?.complete && app.stepStatus?.complete === app.stepStatus?.count ? (
      <Trans i18nKey="summary.household.adults.complete">Complete</Trans>
    ) : !app.stepStatus?.count ? (
      <Trans i18nKey="summary.household.adults.progress">In Progress</Trans>
    ) : (
      `${app.stepStatus?.complete} of ${app.stepStatus?.count}`
    );
  };

  return (
    <ItemWrapper className={isMobile ? 'mobile' : ''}>
      {isMobile ? (
        otherContacts.map((oc, ix) => {
          return (
            <div key={ix}>
              <p>
                <strong>{getFullName(oc)}</strong>
                <br />
                {getType(oc)}
                <br />
                {oc.email}
                <br />
                {oc.phone?.map((p) => p.number).join(', ')} <br />
                <span>
                  <Trans i18nKey="summary.household.col.status">Status</Trans>:
                </span>
                {getStatus(oc)}
              </p>
            </div>
          );
        })
      ) : (
        <Table>
          <tbody>
            <tr>
              <th>
                <Trans i18nKey="summary.household.col.type">Type</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.type">Name</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.email">Email</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.phone">Phone</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.status">Status</Trans>
              </th>
              {includeAddedByCol && (
                <th>
                  <Trans i18nKey="summary.household.col.added_By">Added By</Trans>
                </th>
              )}
            </tr>
            {otherContacts.map((oc, ix) => {
              return (
                <tr key={ix}>
                  <td>{getType(oc)}</td>
                  <td>{getFullName(oc)}</td>
                  <td>{oc.email}</td>
                  <td>{oc.phone?.map((p) => p.number).join(', ')}</td>
                  <td>{getStatus(oc)}</td>
                  {includeAddedByCol && <td>{oc.addedBy || getFullName(application.applicant)}</td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </ItemWrapper>
  );
};

const Minors = ({ application, isMobile }) => {
  const minors = []
    .concat(application?.minors)
    .concat(application.relatedAppData?.minors)
    .filter((m) => m);
  if (!minors.length) return <NoData>None specified</NoData>;

  const includeAddedByCol = !!application.relatedAppData?.minors?.length;
  return (
    <ItemWrapper className={isMobile ? 'mobile' : ''}>
      {isMobile ? (
        minors.map((m, ix) => {
          return (
            <div key={ix}>
              <p>
                <strong>{getFullName(m)}</strong>
                <br />
                <strong>
                  <Trans i18nKey="summary.household.col.age">Age</Trans>
                </strong>{' '}
                {m.dateOfBirth ? Math.floor(new Date(m.dateOfBirth).daysSince() / 365) : '-'}
                <br />
                {includeAddedByCol && (
                  <>
                    <strong>Added By</strong> {m.addedBy || getFullName(application.applicant)}
                  </>
                )}
              </p>
            </div>
          );
        })
      ) : (
        <Table>
          <tbody>
            <tr>
              <th>
                <Trans i18nKey="summary.household.col.name">Name</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.age">Age</Trans>
              </th>
              {includeAddedByCol && (
                <th>
                  <Trans i18nKey="summary.household.col.added_by">Added By</Trans>
                </th>
              )}
            </tr>
            {minors.map((m, ix) => (
              <tr key={ix}>
                <td>{getFullName(m)}</td>
                <td>
                  {m.dateOfBirth ? Math.floor(new Date(m.dateOfBirth).daysSince() / 365) : '-'}
                </td>
                {includeAddedByCol && <td>{m.addedBy || getFullName(application.applicant)}</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </ItemWrapper>
  );
};

const Vehicles = ({ t, application, isMobile }) => {
  const vehicles = []
    .concat(application?.vehicles)
    .concat(application.relatedAppData?.vehicles)
    .filter((m) => m);
  if (!vehicles.length) return <NoData>None specified</NoData>;

  const includeAddedByCol = !!application.relatedAppData?.vehicles?.length;

  const OPTIONS = {
    car: `${t('vehicleStep.type.car')}`,
    trailer: `${t('vehicleStep.type.trailer')}`,
    motorcycle: `${t('vehicleStep.type.motorcycle')}`,
    other: `${t('vehicleStep.type.other')}`
  };

  return (
    <ItemWrapper className={isMobile ? 'mobile' : ''}>
      {isMobile ? (
        vehicles.map((v, ix) => {
          return (
            <div key={ix}>
              <p>
                <strong>
                  <Trans i18nKey="summary.household.col.type">Type</Trans>
                </strong>{' '}
                {OPTIONS[v.type]}
                <br />
                <strong>
                  <Trans i18nKey="summary.household.col.make">Make</Trans>/
                  <Trans i18nKey="summary.household.col.model">Model</Trans>
                </strong>{' '}
                {v.make} / {v.model} <br />
                <strong>
                  <Trans i18nKey="summary.household.col.color">Color</Trans>
                </strong>{' '}
                {v.color} <br />
                <strong>
                  <Trans i18nKey="summary.household.col.license_plate">Lic. Plate</Trans>
                </strong>{' '}
                {v.licensePlate} <br />
                <strong>
                  <Trans i18nKey="summary.household.col.corp">Corp/Oversized</Trans>
                </strong>{' '}
                {v.oversized ? t('yes') : t('no')} <br />
                {includeAddedByCol && (
                  <>
                    <strong>
                      <Trans i18nKey="summary.household.col.added_by">Added By</Trans>
                    </strong>{' '}
                    {v.addedBy || getFullName(application.applicant)}
                  </>
                )}
              </p>
            </div>
          );
        })
      ) : (
        <Table>
          <tbody>
            <tr>
              <th>
                <Trans i18nKey="summary.household.col.type">Type</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.make">Make</Trans>/
                <Trans i18nKey="summary.household.col.model">Model</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.color">Color</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.license_plate">Lic. Plate</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.corp">Corp/Oversized</Trans>
              </th>
              {includeAddedByCol && (
                <th>
                  <Trans i18nKey="summary.household.col.added_by">Added By</Trans>
                </th>
              )}
            </tr>
            {vehicles.map((v, ix) => {
              return (
                <tr key={ix}>
                  <td>{OPTIONS[v.type]}</td>
                  <td>
                    {v.make} / {v.model}
                  </td>
                  <td>{v.color}</td>
                  <td>{v.licensePlate}</td>
                  <td>{v.oversized ? 'Yes' : 'No'}</td>
                  {includeAddedByCol && <td>{v.addedBy || getFullName(application.applicant)}</td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </ItemWrapper>
  );
};

const Animals = ({ application, t, isMobile }) => {
  const pets = []
    .concat(application?.pets)
    .concat(application.relatedAppData?.pets)
    .filter((m) => m);
  if (!pets.length) return <NoData>None specified</NoData>;

  const includeAddedByCol = !!application.relatedAppData?.pets?.length;

  const OPTIONS = {
    cat: `${t('cat')}`,
    dog: `${t('dog')}`,
    other: `${t('other')}`
  };
  return (
    <ItemWrapper className={isMobile ? 'mobile' : ''}>
      {isMobile ? (
        pets.map((p, ix) => {
          return (
            <div key={ix}>
              <p>
                <strong>{p.name}</strong>
                <br />
                <strong>
                  <Trans i18nKey="summary.household.col.type">Type</Trans>
                </strong>{' '}
                {OPTIONS[p.type]} <br />
                <strong>
                  <Trans i18nKey="summary.household.col.breed">Breed</Trans>
                </strong>{' '}
                {p.breed} <br />
                <strong>
                  <Trans i18nKey="summary.household.col.weight">Weight</Trans>
                </strong>{' '}
                {p.weight} Lbs <br />
                <strong>AA?</strong> {p.serviceAnimal ? t('yes') : t('no')} <br />
                {includeAddedByCol && (
                  <>
                    <strong>
                      <Trans i18nKey="summary.household.col.added_by">Added By</Trans>
                    </strong>{' '}
                    {p.addedBy || getFullName(application.applicant)}
                  </>
                )}
              </p>
            </div>
          );
        })
      ) : (
        <Table>
          <tbody>
            <tr>
              <th>
                <Trans i18nKey="summary.household.col.type">Type</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.name">Name</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.breed">Breed</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.weight">Weight</Trans>
              </th>
              <th>AA?</th>
              {includeAddedByCol && (
                <th>
                  <Trans i18nKey="summary.household.col.added_by">Added By</Trans>
                </th>
              )}
            </tr>
            {pets.map((p, ix) => {
              return (
                <tr key={ix}>
                  <td>{OPTIONS[p.type]}</td>
                  <td>{p.name}</td>
                  <td>{p.breed}</td>
                  <td>{p.weight}</td>
                  <td>{p.serviceAnimal ? t('yes') : t('no')}</td>
                  {includeAddedByCol && <td>{p.addedBy || getFullName(application.applicant)}</td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </ItemWrapper>
  );
};

const Questions = ({ application, t, location, isMobile }) => {
  const qs = location?.applicationConfig?.questionnaire || [];
  if (!qs.length) return <NoData>None specified</NoData>;

  return (
    <ItemWrapper className={isMobile ? 'mobile' : ''}>
      {isMobile ? (
        qs.map((q, ix) => {
          const appInfo = application.questionnaire?.[q.uuid] || {};

          let state = {};
          if (q.type === 'bool') {
            if (appInfo.value) {
              state.color = 'red';
              state.label = t('yes');
              state.icon = 'fa-circle-check';
            } else if (appInfo.value === false) {
              state.color = 'green';
              state.label = t('no');
              state.icon = 'fa-circle';
            } else {
              state.color = 'gray';
              state.label = t('unanswered');
              state.icon = 'fa-circle';
            }
          }
          return (
            <div key={ix}>
              <p>{q.question}</p>
              {q.type === 'bool' && (
                <div>
                  <span style={{ color: state.color, marginRight: '10px' }}>
                    <Icon name={`fa-duotone ${state.icon}`} /> {state.label}
                  </span>
                </div>
              )}
              {appInfo.notes && (
                <p>
                  <strong>Comments</strong> <br /> {appInfo.notes}
                </p>
              )}
            </div>
          );
        })
      ) : (
        <Table>
          <tbody>
            <tr>
              <th width="40%">
                <Trans i18nKey="summary.household.col.question">Question</Trans>
              </th>
              <th>
                <Trans i18nKey="summary.household.col.response">Response</Trans>
              </th>
            </tr>
            {qs.map((q, ix) => {
              const appInfo = application.questionnaire?.[q.uuid] || {};

              let state = {};
              if (q.type === 'bool') {
                if (appInfo.value) {
                  state.color = 'red';
                  state.label = t('yes');
                  state.icon = 'fa-circle-check';
                } else if (appInfo.value === false) {
                  state.color = 'green';
                  state.label = t('no');
                  state.icon = 'fa-circle';
                } else {
                  state.color = 'gray';
                  state.label = t('unanswered');
                  state.icon = 'fa-circle';
                }
              }

              return (
                <tr key={ix}>
                  <td width="40%">{q.question}</td>
                  <td>
                    {q.type === 'bool' && (
                      <span style={{ color: state.color, marginRight: '10px' }}>
                        <Icon name={`fa-duotone ${state.icon}`} /> {state.label}
                      </span>
                    )}
                    {appInfo.notes}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </ItemWrapper>
  );
};

export default ({ application, location, t }) => {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  return (
    <Section title={<Trans i18nKey="summary.household.title">HOUSEHOLD INFO</Trans>}>
      <Row>
        <div>
          <Icon name="fa-regular fa-people-simple" />{' '}
          <strong>
            <Trans i18nKey="summary.household.adults.title">Adults</Trans>
          </strong>
        </div>
        <Adults t={t} isMobile={isMobile} application={application} />
      </Row>
      <Row>
        <div>
          <Icon name="fa-regular fa-children" />{' '}
          <strong>
            <Trans i18nKey="summary.household.minors.title">Minors</Trans>
          </strong>
        </div>
        <Minors t={t} isMobile={isMobile} application={application} />
      </Row>
      <Row>
        <div>
          <Icon name="fa-regular fa-car-side" />{' '}
          <strong>
            <Trans i18nKey="summary.household.vehicles.title">Vehicles</Trans>
          </strong>
        </div>
        <Vehicles t={t} isMobile={isMobile} application={application} />
      </Row>
      {!['petScreening'].includes(location.companyInfo?.petScreeningType) && (
        <Row>
          <div>
            <Icon name="fa-regular fa-dog" />{' '}
            <strong>
              <Trans i18nKey="summary.household.pets.title">Animals</Trans>
            </strong>
          </div>
          <Animals t={t} isMobile={isMobile} application={application} />
        </Row>
      )}
      <Row>
        <div>
          <Icon name="fa-regular fa-square-question" />{' '}
          <strong>
            <Trans i18nKey="summary.household.questions.title">Additional Questions</Trans>
          </strong>
        </div>
        <Questions t={t} isMobile={isMobile} location={location} application={application} />
      </Row>
    </Section>
  );
};
